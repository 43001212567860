import {
  // Paper,
  Text,
  TextInput,
  Textarea,
  Button,
  Group,
  SimpleGrid,
  createStyles,
} from "@mantine/core";

const useStyles = createStyles((theme) => {
  const BREAKPOINT = theme.fn.smallerThan("sm");

  return {
    wrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      borderRadius: theme.radius.lg,
      padding: 4,
      //   border: `1px solid ${
      //     theme.colorScheme === "dark"
      //       ? theme.colors.dark[8]
      //       : theme.colors.gray[2]
      //   }`,

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    form: {
      boxSizing: "border-box",
      //   flex: 1,
      //   padding: theme.spacing.xl,
      //   paddingLeft: theme.spacing.xl * 2,
      //   borderLeft: 0,

      [BREAKPOINT]: {
        padding: theme.spacing.md,
        paddingLeft: theme.spacing.md,
      },
    },

    fields: {
      marginTop: -12,
    },

    fieldInput: {
      flex: 1,

      "& + &": {
        marginLeft: theme.spacing.md,

        [BREAKPOINT]: {
          marginLeft: 0,
          marginTop: theme.spacing.md,
        },
      },
    },

    fieldsGroup: {
      display: "flex",

      [BREAKPOINT]: {
        flexDirection: "column",
      },
    },

    title: {
      marginBottom: theme.spacing.xl * 1.5,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,

      [BREAKPOINT]: {
        marginBottom: theme.spacing.xl,
      },
    },

    control: {
      [BREAKPOINT]: {
        flex: 1,
      },
    },
  };
});

export function GetInTouch() {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <form
        className={classes.form}
        onSubmit={(event) => event.preventDefault()}
      >
        <Text size="xl" weight={700} className={classes.title}>
          Get in touch
        </Text>

        <div className={classes.fields}>
          <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
            <TextInput placeholder="Name" />
            <TextInput placeholder="Email" required />
          </SimpleGrid>

          <TextInput mt="md" placeholder="Subject" required />

          <Textarea mt="md" placeholder="Message" minRows={3} />

          <Group position="right" mt="md">
            <Button type="submit" className={classes.control}>
              Send message
            </Button>
          </Group>
        </div>
      </form>
    </div>
  );
}
