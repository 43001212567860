import { MediaQuery } from "@mantine/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { LoadingSpinner } from "../Loading/LoadingSpinner";
import { ProjectCard } from "./ProjectsCard";

export const ProjectWrap = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get("https://ajsabesirovic.deta.dev/projects").then((r: any) => {
      setData(r.data);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  }, []);
  console.log(data);

  return (
    <MediaQuery
      styles={{ flexDirection: "column", alignItems: "center" }}
      query="(max-width:1000px)"
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          data.map((project: any) => (
            <ProjectCard
              key={project.link}
              title={project.name}
              description={project.desc}
              link={project.link}
            />
          ))
        )}
      </div>
    </MediaQuery>
  );
};
