import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GetInTouch } from "./components/Contact/Contact";
import { Layout } from "./components/Layout/Layout";
import { Hero } from "./components/Hero/Hero";
import { ProjectWrap } from "./components/Projects/ProjectWrapper";
import {
  MantineProvider,
  ColorScheme,
  ColorSchemeProvider,
} from "@mantine/core";
import { useState } from "react";

export const App = () => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleScheme = (value?: ColorScheme) => {
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  };
  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleScheme}
    >
      <MantineProvider
        theme={{ colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <ProjectWrap />
                </Layout>
              }
            />
            <Route path="/" element={<Hero />} />

            <Route
              path="/contact"
              element={
                <Layout>
                  <GetInTouch />
                </Layout>
              }
            />
          </Routes>
        </BrowserRouter>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
