import { Headers } from "../Header/Header";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = (props: LayoutProps) => {
  return (
    <div>
      <Headers
        links={[
          { label: "About", link: "/about" },
          { label: "Work", link: "/work" },
          { label: "Contact", link: "/contact" },
        ]}
      />
      {props.children}
    </div>
  );
};
