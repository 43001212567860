import { useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { SwitchToggle } from "../ColorToggle/SwitchToggle";

const HEADER_HEIGHT = 80;

const useStyles = createStyles((theme) => ({
  root: {
    position: "sticky",
    zIndex: 1,
    borderBottom: 0,
    boxShadow: theme.shadows.md,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "0% 5%",
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    transition: "all ease-in 0.25s",
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "gray",
    fontSize: theme.fontSizes.md,
    fontWeight: 500,

    "&:hover": {
      color: "black",
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      color: "gray",
    },
  },
  logo: {
    fontSize: 30,
    fontWeight: 700,
  },
}));

interface HeaderResponsiveProps {
  links: { link: string; label: string }[];
}

export const Headers = ({ links }: HeaderResponsiveProps) => {
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.link,
      })}
      onClick={() => {
        setActive(link.link);
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={HEADER_HEIGHT} mb={30} className={classes.root}>
      <Container className={classes.header}>
        <Group className={classes.links}>
          <Text className={classes.logo}>Ajsa Besirovic</Text>
        </Group>
        <Group className={classes.links}>{items}</Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
        <SwitchToggle />
      </Container>
    </Header>
  );
};
