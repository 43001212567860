import { createStyles, Paper, Text, MediaQuery } from "@mantine/core";
import { IconColorSwatch } from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  card: {
    width: "100%",
    borderRadius: 0,
    border: "7px solid white",
    padding: "10%",
    background:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : "rgb(230,230,230)",
    cursor: "pointer",
    transition: "transform 300ms ease, box-shadow 250ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.02)",
    },

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
    },
  },
  icon: {
    background: "none",
    border: "4px solid white",
    padding: "2%",
    width: "22%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconwrapper: {
    background: "white",
    padding: "13% 0%",
    width: "100%",
    display: "flex",
    justifyContent: "center ",
    alignItems: "center",
  },
  root: {
    margin: "1% 1%",
    width: "23%",
    border:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : "7px solid rgb(230,230,230)",
  },
}));

interface CardGradientProps {
  title: string;
  description: string;
  link: string;
}

export function ProjectCard({ title, description, link }: CardGradientProps) {
  const { classes } = useStyles();
  return (
    <MediaQuery
      styles={{ width: "70%", marginBottom: "25px" }}
      query="(max-width:1000px)"
    >
      <div
        className={classes.root}
        onClick={() => {
          window.open(link, "_blank");
        }}
      >
        <Paper withBorder radius="md" className={classes.card}>
          <div className={classes.icon}>
            <div className={classes.iconwrapper}>
              <IconColorSwatch size={40} stroke={0.7} />
            </div>
          </div>
          <Text size="xl" weight={500} mt="md">
            {title}
          </Text>
          <Text size="sm" mt="sm" color="dimmed">
            {description}
          </Text>
        </Paper>
      </div>
    </MediaQuery>
  );
}
